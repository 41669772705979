import type { ThemeDefinition } from 'vuetify';

export const accadisISBTheme: ThemeDefinition = {
    dark: false,
    colors: {
        // primary: '#F9B122',
        // secondary: 'F9B122',
        // accent: '#ffffff',

        navbg: '#F9B122',
        navtext: '#ffffff',
        menuheadertext: '#7d7d7d',
    },
    variables: {
        logo: '/img/logo-isb.svg',
        defaultNewsImg: '/img/isb-news-default.webp',
    },
};
