import type { ThemeDefinition } from 'vuetify';

export const accadisBITheme: ThemeDefinition = {
    dark: false,
    colors: {
        // primary: '#DEDEDE',
        // secondary: '#7d7d7d',
        // accent: '#7d7d7d',

        navbg: '#DEDEDE',
        navtext: '#7d7d7d',
        menuheadertext: '#7d7d7d',
    },
    variables: {
        logo: '/img/logo-bi.svg',
        defaultNewsImg: '/img/hs-default.jpg',
    },
};
