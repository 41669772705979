import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFlocklerCommon, LazyLoginComponent, LazyMediaImage, LazyMenuComponent, LazyNavigationBarImage, LazyNewsCardComponent, LazyProgressionCounter, LazyProtoNavigationBar, LazyQRCode, LazySafeHTML, LazyContainerError, LazyContainerFlocklerHS, LazyContainerFlocklerISB, LazyContainerImageFilmHS, LazyContainerImageFilmHSShort, LazyContainerImageFilmISB, LazyContainerImageFilmISBShort, LazyContainerImageFilmLebtVielfalt, LazyContainerInfoEvent, LazyContainerLibraryRules, LazyContainerMedia1, LazyContainerMedia2, LazyContainerMedia3, LazyContainerMedia4, LazyContainerMenuHS, LazyContainerMenuISBES, LazyContainerMenuISBSES, LazyContainerNewsHS, LazyContainerNewsISB, LazyContainerPortraits, LazyContainerRoomPlan, LazyNavigationBarError, LazyNavigationBarFlocklerHS, LazyNavigationBarFlocklerISB, LazyNavigationBarImageFilmHS, LazyNavigationBarImageFilmHSShort, LazyNavigationBarImageFilmISB, LazyNavigationBarImageFilmISBShort, LazyNavigationBarImageFilmLebtVielfalt, LazyNavigationBarInfoEvent, LazyNavigationBarLibraryRules, LazyNavigationBarMedia1, LazyNavigationBarMedia2, LazyNavigationBarMedia3, LazyNavigationBarMedia4, LazyNavigationBarMenuHS, LazyNavigationBarMenuISBES, LazyNavigationBarMenuISBSES, LazyNavigationBarNewsHS, LazyNavigationBarNewsISB, LazyNavigationBarPortraits, LazyNavigationBarRoomPlan } from '#components'
const lazyGlobalComponents = [
  ["FlocklerCommon", LazyFlocklerCommon],
["LoginComponent", LazyLoginComponent],
["MediaImage", LazyMediaImage],
["MenuComponent", LazyMenuComponent],
["NavigationBarImage", LazyNavigationBarImage],
["NewsCardComponent", LazyNewsCardComponent],
["ProgressionCounter", LazyProgressionCounter],
["ProtoNavigationBar", LazyProtoNavigationBar],
["QRCode", LazyQRCode],
["SafeHTML", LazySafeHTML],
["ContainerError", LazyContainerError],
["ContainerFlocklerHS", LazyContainerFlocklerHS],
["ContainerFlocklerISB", LazyContainerFlocklerISB],
["ContainerImageFilmHS", LazyContainerImageFilmHS],
["ContainerImageFilmHSShort", LazyContainerImageFilmHSShort],
["ContainerImageFilmISB", LazyContainerImageFilmISB],
["ContainerImageFilmISBShort", LazyContainerImageFilmISBShort],
["ContainerImageFilmLebtVielfalt", LazyContainerImageFilmLebtVielfalt],
["ContainerInfoEvent", LazyContainerInfoEvent],
["ContainerLibraryRules", LazyContainerLibraryRules],
["ContainerMedia1", LazyContainerMedia1],
["ContainerMedia2", LazyContainerMedia2],
["ContainerMedia3", LazyContainerMedia3],
["ContainerMedia4", LazyContainerMedia4],
["ContainerMenuHS", LazyContainerMenuHS],
["ContainerMenuISBES", LazyContainerMenuISBES],
["ContainerMenuISBSES", LazyContainerMenuISBSES],
["ContainerNewsHS", LazyContainerNewsHS],
["ContainerNewsISB", LazyContainerNewsISB],
["ContainerPortraits", LazyContainerPortraits],
["ContainerRoomPlan", LazyContainerRoomPlan],
["NavigationBarError", LazyNavigationBarError],
["NavigationBarFlocklerHS", LazyNavigationBarFlocklerHS],
["NavigationBarFlocklerISB", LazyNavigationBarFlocklerISB],
["NavigationBarImageFilmHS", LazyNavigationBarImageFilmHS],
["NavigationBarImageFilmHSShort", LazyNavigationBarImageFilmHSShort],
["NavigationBarImageFilmISB", LazyNavigationBarImageFilmISB],
["NavigationBarImageFilmISBShort", LazyNavigationBarImageFilmISBShort],
["NavigationBarImageFilmLebtVielfalt", LazyNavigationBarImageFilmLebtVielfalt],
["NavigationBarInfoEvent", LazyNavigationBarInfoEvent],
["NavigationBarLibraryRules", LazyNavigationBarLibraryRules],
["NavigationBarMedia1", LazyNavigationBarMedia1],
["NavigationBarMedia2", LazyNavigationBarMedia2],
["NavigationBarMedia3", LazyNavigationBarMedia3],
["NavigationBarMedia4", LazyNavigationBarMedia4],
["NavigationBarMenuHS", LazyNavigationBarMenuHS],
["NavigationBarMenuISBES", LazyNavigationBarMenuISBES],
["NavigationBarMenuISBSES", LazyNavigationBarMenuISBSES],
["NavigationBarNewsHS", LazyNavigationBarNewsHS],
["NavigationBarNewsISB", LazyNavigationBarNewsISB],
["NavigationBarPortraits", LazyNavigationBarPortraits],
["NavigationBarRoomPlan", LazyNavigationBarRoomPlan],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
