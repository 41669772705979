import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { VBtn, VDataTable } from 'vuetify/components';
import { VSnackbarQueue } from 'vuetify/labs/VSnackbarQueue';
import { aliases } from 'vuetify/iconsets/mdi-svg';
import { VDateInput } from 'vuetify/labs/VDateInput';
import { accadisHSTheme } from '~/themes/accadisHSTheme';
import { accadisISBTheme } from '~/themes/accadisISBTheme';
import { accadisBITheme } from '~/themes/accadisBITheme';

export default defineNuxtPlugin((nuxtApp) => {
    const vuetify = createVuetify({
        ssr: true,

        icons: {
            aliases: {
                ...aliases,
                // Icons
                infoIcon: 'mdi-information',
                specialIcon: 'mdi-seat',
                moduleIcon: 'mdi-school',
                eventIcon: 'mdi-calendar',
                unclassifiedIcon: 'mdi-folder-question-outline',
            },
        },
        components: {
            ...components,
            VSnackbarQueue,
            VDateInput,
        },
        directives,
        theme: {
            defaultTheme: 'accadisHSTheme',
            themes: {
                accadisHSTheme,
                accadisISBTheme,
                accadisBITheme,
            },
        },
        aliases: {
            OkBtn: VBtn,
            VDevBtn: VBtn,
            GoogleTable: VDataTable,
            VPlainTextField: components.VTextField,
            STextField: components.VTextField,
            SSelect: components.VSelect,
            SCombobox: components.VCombobox,
            Alpha: components.VChip,
            Beta: components.VChip,
        },
        defaults: {
            GoogleTable: {
                color: 'success',
                density: 'compact',
                variant: 'outlined',
                hover: true,
            },
            OkBtn: {
                color: 'primary',
                variant: 'tonal',
                rounded: '0',
                elevation: '4',
                size: 'large',
            },

            VCard: {
                variant2: 'flat',
                rounded: '0',
            },

            VDevBtn: {
                density: 'compact',
                variant: 'outlined',
                rounded: '0',
            },
            global: {
                ripple: true,
            },

            Alpha: {
                color: 'error',
                text: 'Alpha',
            },
            Beta: {
                color: 'warning',
                text: 'Beta',
            },

            VTextField: {},
            STextField: {
                density: 'compact',
                variant: 'outlined',
            },
            SSelect: {
                density: 'compact',
                variant: 'underlined',
                chips: true,
            },
            SCombobox: {
                density: 'compact',
                variant: 'underlined',
                chips: true,

            },
            VPlainTextField:
            {
                variant: 'plain',
                density: 'compact',
                class: 'font-weight-bold',
            },
        },
    });

    nuxtApp.vueApp.use(vuetify);
});
